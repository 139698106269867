<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <b-card>
    <b-row class="align-items-end mb-2">
      <b-col md="3" xl="3">
        <h6>Cấp học</h6>
        <b-form-group class="mb-0">
          <v-select
              v-model="modelStudentManagementLevelId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :disabled="checkLevel"
              :options="schoolLevel"
              placeholder="Chọn cấp học"
              :reduce="(option) => option.id"
              @input="getListClass"
          />
        </b-form-group>
      </b-col>
      <b-col md="3" xl="3">
        <h6>Khối</h6>
        <b-form-group class="mb-0">
          <v-select
              v-model="modelStudentManagement.classId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="optionClass"
              placeholder="Chọn khối"
              :reduce="(option) => option.id"
              :disabled="checkClassEntity"
          >
            <span slot="no-options">Không có dữ liệu.</span>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="3" xl="3">
        <h6>Trạng thái hồ sơ</h6>
        <b-form-group class="mb-0">
          <v-select
              v-model="modelStudentManagement.admissionStatus"
              :options="admissionStatusOptions" placeholder="Trạng thái hồ sơ" :reduce="(option) => option.value"
          />
        </b-form-group>
      </b-col>
      <b-col md="3" ml="3">
        <h6>Lịch sử liên lạc</h6>
        <b-form-group class="mb-0">
          <v-select v-model="modelStudentManagement.status"
                    :options="statusList"
                    :reduce="(option) => option.value"
                    placeholder="Chọn lịch sử"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4" xl="4">
        <h6>Tìm kiếm theo từ khoá</h6>
        <b-form-group class="mb-0">
          <input
              v-model="modelStudentManagement.keyWord"
              type="text"
              placeholder="Nhập từ khoá"
              class="form-control"
              @keyup.enter="getListAllStudent"
          >
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label-for="item-dob">
          <h6>Ngày bắt đầu</h6>
          <flat-pickr v-model="modelStudentManagement.startDate" class="form-control"
                      :config="configDOBFlatpickr" placeholder="Chọn ngày bắt đầu"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <h6>Ngày kết thúc</h6>
        <b-form-group label-for="item-dob">
          <flat-pickr v-model="modelStudentManagement.endDate" class="form-control"
                      :config="configDOBFlatpickr" placeholder="Chọn ngày kết thúc"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" xl="4">
        <div class="mt-2">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="getListAllStudent"
          >
            <feather-icon
                icon="SearchIcon"
                class="mr-50"
            />
            <span class="align-middle">Tìm kiếm</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :to="{ name: 'student-management/add-student' }"
            variant="primary" class="mr-2"
        >
          <feather-icon
              icon="PlusIcon"
              class="mr-50"
          />
          <span class="align-middle">Thêm mới</span>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" class="mr-2" @click="exportFileExcelStudents"
        >
          <feather-icon
              icon="DownloadIcon"
              class="mr-50"
          />
          <span class="align-middle">Xuất danh sách</span>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" class="mr-2" @click="downloadSampleFile"
        >
          <feather-icon
              icon="DownloadIcon"
              class="mr-50"
          />
          <span class="align-middle">Tải tập tin mẫu</span>
        </b-button>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" @click="$refs.fileUpload.click()"
        >
          <feather-icon
              icon="UploadIcon"
              class="mr-50"
          />
          <span class="align-middle">Thêm tập tin</span>
        </b-button>
        <input ref="fileUpload" type="file" id="fileUpload" accept=".xlsx, .xls, .csv, .xlsm, .xlsb"
               class="hidden" @change="importFileExcelStudents($event)">

      </b-col>
    </b-row>
    <div class="data-table-link mt-1">
      <div class="row">
        <div class="col-md-12">
          <div class="table-data-employee">
            <span class="float-right mb-2">Có tổng số {{ totalRecordsList }} bản ghi</span>
            <b-table
                responsive
                :items="listStudent"
                :fields="fieldsTable"
                class="mb-0"
            >
              <template #cell(levelId)="data">
                <label v-if="data.value === 1">
                  Cấp Tiểu học
                </label>
                <label v-if="data.value === 2">
                  Cấp THCS
                </label>
                <label v-if="data.value === 3">
                  Cấp THPT
                </label>
                <label v-if="data.value === 4">
                  Mầm non
                </label>
              </template>
              <template #cell(classEntity)="data">
                <label>
                  {{ (data.value.name) }} - {{ (getInfoClass(data.value.classType)) }}
                </label>
              </template>
              <template #cell(admissionStatus)="data">
                <label
                    v-if="data.value != 1"
                    class="fontSizeLabel fail-exam" style="border-radius: 10px; padding-inline: 1rem"
                >
                  Chưa trúng tuyển
                </label>
                <label
                    v-else-if="data.value == 1"
                    class="fontSizeLabel pass-exam" style="border-radius: 10px; padding-inline: 1rem"
                >
                  Đã trúng tuyển
                </label>
<!--                <label-->
<!--                    v-else-if="data.value != 1 && data.value != 0"-->
<!--                    class="fontSizeLabel"-->
<!--                >-->
<!--                  Chưa xác định-->
<!--                </label>-->
              </template>
              <template #cell(hanhdong)="data">
                <b-button
                    v-b-tooltip.hover.top="'Cập nhật'"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="getDetailStudent(data.item.id)"
                >
                  <feather-icon icon="EditIcon"/>
                </b-button>
                <b-button
                    v-b-tooltip.hover.top="'Xoá'"
                    variant="flat-danger"
                    class="btn-icon"
                    @click="deleteStudent(data.item)"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </template>
            </b-table>
            <div class="pagination-custom mt-2 float-right" v-if="totalRecordsList > 0">
              <template>
                <b-pagination
                    v-model="currentPage"
                    :per-page="modelStudentManagement.pageSize"
                    :total-rows="totalRecordsList"
                    first-number
                    last-number
                    @change="onChangePagination"
                />
              </template>
            </div>
            <div class="mt-2 d-flex justify-content-center" v-else>
              <span class="text-secondary">Không có dữ liệu</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="error_import" ref="error_import" size="lg" header-bg-variant="danger"
             header-text-variant="warning" hide-footer title="Lỗi thêm tập tin">
      <b-table striped hover :items="errorImportData" :fields="fieldsError"></b-table>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardText, BCol, BFormGroup, BPagination, BRow, BTable, VBTooltip, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import {Vietnamese} from 'flatpickr/dist/l10n/vn'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BButton,
    BTable,
    BPagination,
    flatPickr,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      checkLevel: false,
      totalRecordsList: 0,
      records: 0,
      currentPage: 1,
      modelStudentManagement: {
        levelId: null,
        classId: 'null',
        status: null,
        pageIndex: 0,
        pageSize: 10,
        keyWord: '',
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
        endDate: new Date(),
      },
      fieldsTable: [
        {
          key: 'studentCode', label: 'Mã HS',
        },
        {
          key: 'fullName', label: 'Tên học sinh',
        },
        {
          key: 'levelId', label: 'Cấp học',
        },
        {
          key: 'classEntity', label: 'Khối',
        },
        {
          key: 'phoneNumber', label: 'Số điện thoại',
        },
        {
          key: 'admissionStatus', label: 'Trạng thái',
        },
        {
          key: 'hanhdong', label: 'Hành động',
        },
      ],
      listStudent: [],
      schoolLevel: [],
      optionClass: [{name: 'Tất cả', id: 'null'}],
      statusList: [
        {label: 'Chưa liên lạc', value: 0},
        {label: 'Liên lạc lần 1', value: 1},
        {label: 'Liên lạc lần 2', value: 2},
        {label: 'Liên lạc lần 3', value: 3},
        {label: 'Liên lạc lần 4', value: 4},
        {label: 'Liên lạc lần 5', value: 5},
      ],
      admissionStatusOptions: [
        {label: 'Đã trúng tuyển', value: 1},
        {label: 'Chưa trúng tuyển', value: 0},
      ],
      configDOBFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
      errorImportData: [],
      fieldsError: [
        {
          key: 'stt', label: 'STT',
        },
        {
          key: 'header', label: 'Cột',
        },
        {
          key: 'rowNumError', label: 'Hàng',
        },
        {
          key: 'message', label: 'Lỗi',
        },
      ],
      checkClassEntity: true,
      levelIds: [],
      modelStudentManagementLevelId: null,
    }
  },
  created() {
    const userCheckRole = JSON.parse(localStorage.getItem('dataMenuUser'))
    userCheckRole.map(x => {
      if (x.level.length > 1) {
        this.levelIds = x.level
        this.modelStudentManagementLevelId = null
        this.checkLevel = false
        this.getListClass()
      }
      if (x.level.length === 1) {
        this.levelIds = x.level
        this.modelStudentManagementLevelId = null
        this.checkLevel = true
        this.getListClass()
      }
    })
    this.getListAllStudent()
    this.getListSchool(userCheckRole)
  },
  methods: {
    getListSchool(userCheckRole) {
      this.$crm.get('level-school/all').then(res => {
        this.schoolLevel = res.data
        userCheckRole.map(x => {
          if (x.level.length > 0) {
            let obDTO = []
            res.data.forEach(el => {
              let data = x.level.find(x => x === el.id)
              if (data) obDTO.push(el)
            })
            this.schoolLevel = obDTO
          }
        })
        this.schoolLevel.unshift({name: 'Tất cả', id: 'null'})
      })
    },
    getInfoClass(classType) {
      // eslint-disable-next-line default-case
      switch (classType) {
        case 0:
          return 'STEAM'
        case 1:
          return 'CLC'
        case 2:
          return 'NKNN'
        case 4:
          return 'STEAM A'
        case 5:
          return 'STEAM D'
        case 6:
          return 'CLC A'
        case 7:
          return 'CLC D'
      }
    },
    getListClass() {
      this.optionClass = []
      if (this.modelStudentManagementLevelId > 0 && this.modelStudentManagementLevelId < 4) {
        this.checkClassEntity = false
        this.$crm.get(`class-entity/find-by-level-school-id/${this.modelStudentManagementLevelId}`).then(res => {
          if (res.data && res.data.length > 0) {
            this.optionClass = res.data
            this.optionClass.forEach(item => {
              if (item.classType === 0) {
                item.name = `${item.name} STEAM`
              }
              if (item.classType === 1) {
                item.name = `${item.name} CLC`
              }
              if (item.classType === 2) {
                item.name = `${item.name} NKNN`
              }
              if (item.classType === 4) {
                item.name = `${item.name} Steam A`
              }
              if (item.classType === 5) {
                item.name = `${item.name} Steam D`
              }
              if (item.classType === 6) {
                item.name = `${item.name} CLC A`
              }
              if (item.classType === 7) {
                item.name = `${item.name} CLC D`
              }
            })
            this.optionClass.unshift({name: 'Tất cả', id: 'null'})
          }
        })
      } else {
        this.checkClassEntity = true
        this.modelStudentManagement.classId = 'null'
        this.optionClass.unshift({name: 'Tất cả', id: 'null'})
      }
    },
    getListAllStudent() {
      this.modelStudentManagement.pageIndex = 0
      this.modelStudentManagement.startDate = this.modelStudentManagement.startDate ? new Date(this.modelStudentManagement.startDate).toISOString() : null
      this.modelStudentManagement.endDate = this.modelStudentManagement.endDate ? new Date(this.modelStudentManagement.endDate).toISOString() : null
      this.modelStudentManagement.levelId = this.modelStudentManagementLevelId ? [this.modelStudentManagementLevelId] : this.levelIds.length > 0 ? this.levelIds : null
      this.modelStudentManagement.classId = JSON.parse(this.modelStudentManagement.classId)
      this.$crm.post('student-register/find-by-condition', this.modelStudentManagement).then(res => {
        this.listStudent = res.data.listData.map(el => {
          el.phoneNumber = el.fatherNumber ? el.fatherNumber : el.motherNumber ? el.motherNumber : el.guardianNumber ? el.guardianNumber : null
          return el
        })
        this.totalRecordsList = res.data.totalRecords
        this.records = res.data.records
      })
    },
    deleteStudent(item) {
      const fullNameCv = item.fullName.toLocaleUpperCase()
      this.$swal({
        title: 'Xóa hồ sơ',
        text: `Bạn có muốn xóa hồ sơ học sinh  ${fullNameCv}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.post(`student-register/delete/${item.id}`).then(() => {
            this.getListAllStudent()
            this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Thành công!',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: 'Xóa thành công hồ sơ học sinh',
                  },
                },
                {timeout: 4000})
          })
        }
      })
    },
    getDetailStudent(item) {
      this.$router.push({
        name: 'student-management/update-student',
        query: {
          id: item,
        },
      })
    },
    onChangePagination(currentPage) {
      this.modelStudentManagement.pageIndex = currentPage - 1
      this.$crm.post('student-register/find-by-condition', this.modelStudentManagement).then(res => {
        this.listStudent = res.data.listData
        this.totalRecordsList = res.data.totalRecords
        this.records = res.data.records
      })
    },
    exportFileExcelStudents() {
      this.$crm.post('student-register/export', this.modelStudentManagement).then(res => {
        this.getFileExcel(res.data.fileName)
      })
    },
    getFileExcel(fileName) {
      if (fileName !== '') {
        this.$crm.get(`file/${fileName}`)
            .then(res => {
              const accesstoken = localStorage.getItem('access_token')
              window.open(`${res.config.baseURL}${res.config.url}?access_token=${accesstoken}`, '_blank')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thành công',
                  icon: 'CheckIcon',
                  text: 'Tải file thành công',
                },
              })
            })
      }
    },

    downloadSampleFile() {
      this.$crm.get('guest/download-import-template').then(res => {
        this.getFileExcel(res.data)
      })
    },

    isExcel(file) {
      return /\.(xlsx)$/.test(file.name)
    },


    importFileExcelStudents(e) {
      let file = [...e.target.files];
      if (!this.isExcel(file[0])) {
        this.$refs.fileUpload.value = null;
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi',
            icon: 'XIcon',
            text: 'Chỉ hỗ trợ tệp excel!',
            variant: 'danger'
          },
        })
      }

      let formData = new FormData();
      formData.append('excelFile', file[0]);

      this.$crm.post('guest/import-excel', formData).then(res => {
        if (res.data && res.data.length > 0) {
          this.errorImportData = res.data.map(el => {
            el.stt = res.data.indexOf(el) + 1;
            return el;
          });
          this.$refs['error_import'].show()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thành công',
              icon: 'CheckIcon',
              text: 'Thêm tập tin thành công!',
              variant: 'success'
            },
          })
          this.getListAllStudent();
        }
      })

      this.$refs.fileUpload.value = null;
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select';
@import '../../assets/scss/add-student';
@import '~@core/scss/vue/libs/vue-flatpicker';

.fail-exam {
  background: rgba($danger, .15);
  color: rgba($danger, 1);
}

.pass-exam {
  background: rgba($success, .15);
  color: rgba($success, 1);
}
</style>
